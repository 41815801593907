import React from 'react';

import brokenScreenImage from 'assets/images/404/broken-screen.jpg';

import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';

const NotFound = () => (
  <Layout>
    <Seo image={{ src: brokenScreenImage }} />

    <Section>
      <Image
        image={{ src: brokenScreenImage }}
        alt="Pagina niet gevonden"
        position="right"
        fuckUp
      >
        <Text weight="semi" color="primary-lighter">
          404
        </Text>

        <Heading size="xl" as="h2">
          Helaas! Pagina niet gevonden
        </Heading>

        <Text spacing="lg">
          Het kan zijn dat de pagina die je zocht niet meer bestaat of nooit
          bestaan heeft. Probeer het opnieuw of keer terug naar de homepage.
        </Text>

        <Button color="tertiary" to="/">
          Terug naar homepagina
        </Button>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default NotFound;
